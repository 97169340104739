<template>
  <div>
    <div class="pb-1 d-flex align-center">
      <div class="w-70 pr-3">
        <input-qr-scan-model
          v-model="filters.keywords"
          :label="$t('labels.mobile_goods_search')"
          @keyupEnter="getList"
        ></input-qr-scan-model>
      </div>
      <div class="w-30">
        <input-qr-scan-model
          v-model="filters.storage_location"
          :label="$t('labels.position')"
          @keyupEnter="getList"
        ></input-qr-scan-model>
      </div>
    </div>

    <v-list
      dense
      style="height: calc(100vh - 170px)"
      class="overflow-x-hidden overflow-y-auto"
    >
      <div v-for="(item, index) in items" :key="`${item.id}_${index}`">
        <v-list-item>
          <v-list-item-content class="pb-0">
            <v-list-item-title class="white-space-normal"
              >{{ item.warehouse_code }} -
              {{ item.company_name }}</v-list-item-title
            >
            <v-list-item-title class="white-space-normal">{{
              item.name
            }}</v-list-item-title>
            <v-list-item-subtitle
              class="font-weight-regular white-space-normal"
            >
              <span class="">{{ item.description }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              <div class="d-flex align-center">
                {{ $t("labels.sku") }}: {{ item.sku }}
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.barcode") }}:
              <span class="font-weight-medium error--text">{{
                item.customer_goods_barcode
              }}</span>
              - {{ $t("labels.size") }}:
              <span class="black--text font-weight-medium">{{
                item.size
              }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.sub_barcode") }}:
              <span class="font-weight-medium">{{ item.sub_barcode }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular">
              {{ $t("labels.position") }}:
              <span class="black--text">{{ item.storage_location }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="font-weight-regular mt-2">
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.receiving") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.receiving)
                  }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.stowing") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.stowing)
                  }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.wait_pickup") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.wait_pickup)
                  }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.pickedup") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.pickedup)
                  }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.available") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.available)
                  }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.reserved") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.reserved)
                  }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.returning") }}:</span
                  >
                  <span class="black--text">{{
                    formatNumber(item.returning)
                  }}</span>
                </div>
                <div class="w-50"></div>
              </div>
            </v-list-item-subtitle>

            <!-- v-if="['view1', 'view4'].includes(viewOption)" -->
            <v-list-item-subtitle
              class="font-weight-regular mt-2"
              v-if="['view1', 'view4'].includes(viewOption)"
            >
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.total_volume_split") }}:</span
                  >
                  <span class="black--text"
                    >{{ formatNumber(item.total_volume_split * 100) }}%</span
                  >
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.forecast_out_of_stock") }}:</span
                  >
                  <span class="black--text">{{
                    item.handover_new > 0
                      ? formatNumber(item.available / item.handover_new)
                      : 0
                  }}</span>
                </div>
              </div>
              <div>
                <span style="width: 65px" class="d-inline-block"
                  >{{ $t("labels.sell_volume") }}:</span
                >
                <span class="black--text">{{
                  formatNumber(item.handover_new)
                }}</span>
                ({{ defaultDate().join(" → ") }})
              </div>
            </v-list-item-subtitle>

            <!-- v-if="['view2', 'view4'].includes(viewOption)" -->
            <v-list-item-subtitle
              class="font-weight-regular mt-2"
              v-if="['view2', 'view4'].includes(viewOption)"
            >
              <div>
                <span style="width: 65px" class="d-inline-block"
                  >{{ $t("labels.dxrxc_size") }}:</span
                >
                <span class="black--text">{{ item.dxrxc || "-" }}</span>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.pre_weight") }}:</span
                  >
                  <span class="black--text">{{ item.pre_weight || "-" }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.weight") }}:</span
                  >
                  <span class="black--text">{{
                    (item.use_pre_weight ? item.pre_weight : item.weight) || "-"
                  }}</span>
                </div>
              </div>
            </v-list-item-subtitle>

            <!-- v-if="['view3', 'view4'].includes(viewOption)" -->
            <v-list-item-subtitle
              class="font-weight-regular mt-2"
              v-if="['view3', 'view4'].includes(viewOption)"
            >
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.cost_custom_import") }}:</span
                  >
                  <span class="black--text">{{
                    item.cost_custom_import
                      ? formatNumber(item.cost_custom_import)
                      : "-"
                  }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.cost_custom_store") }}:</span
                  >
                  <span class="black--text">{{
                    item.cost_custom_store
                      ? formatNumber(item.cost_custom_store)
                      : "-"
                  }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.cost_custom_export") }}:</span
                  >
                  <span class="black--text">{{
                    item.cost_custom_export
                      ? formatNumber(item.cost_custom_export)
                      : "-"
                  }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.insurance_value") }}:</span
                  >
                  <span class="black--text">{{
                    item.insurance_value
                      ? formatNumber(item.insurance_value)
                      : "-"
                  }}</span>
                </div>
              </div>
              <div class="d-flex">
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.wholesale_price") }}:</span
                  >
                  <span class="black--text">{{
                    item.wholesale_price
                      ? formatNumber(item.wholesale_price)
                      : "-"
                  }}</span>
                </div>
                <div class="w-50">
                  <span style="width: 65px" class="d-inline-block"
                    >{{ $t("labels.goods_price") }}:</span
                  >
                  <span class="black--text">{{
                    item.price ? formatNumber(item.price) : "-"
                  }}</span>
                </div>
              </div>
              <div class="mt-2">
                <span>{{ $t("labels.uid_printed_on_item") }}: </span>
                <span class="black--text">{{
                  item.uid_printed_on_item ? "Yes" : "No"
                }}</span>
              </div>
              <div>
                <span>{{ $t("labels.note") }}: </span>
                <span class="black--text">{{ item.comment }}</span>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar rounded="0">
            <ImageViewer
              :url="item.url_images || require('@/assets/common/no-image.jpg')"
            />
          </v-list-item-avatar>
        </v-list-item>
        <v-divider v-if="index < items.length - 1"></v-divider>
      </div>
    </v-list>

    <v-divider></v-divider>
    <div>
      <v-pagination
        v-model="page"
        :length="totalPage"
        :total-visible="4"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import list from "@/components/pos_goods/mixins/list";

export default {
  name: "ListMobile",
  components: {
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  mixins: [list],
};
</script>

<style scoped></style>
